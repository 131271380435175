import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "theme-ui"

export const pageQuery = graphql`
  query AboutQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
  }
`
const AboutPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark

  return (
    <Layout className="page">
      <Seo title={frontmatter.title} description={excerpt} />
      <div style={{ paddingTop: 65 }} className="wrapper">
        {/* <h1 style={{ padding: "60px" }}>{frontmatter.title}</h1> */}
        <article
          className="about-navigation"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <br />
        <a href="https://drive.google.com/file/d/1YXQpOZcRQYHU2knuiZDol99eVLlcCDtd/view?usp=sharing">
          <Link
            to="https://drive.google.com/file/d/1YXQpOZcRQYHU2knuiZDol99eVLlcCDtd/view?usp=sharing"
            className="button"
            sx={{
              variant: "variants.button",
            }}
          >
            Curriculm Vitae
          </Link>
        </a>
      </div>
    </Layout>
  )
}

export default AboutPage
